/*
* To have dynamic tabs and pass random content to it, CustomMuiTabs element has
* to have children elements with unique keys:
* "tab-lbl-" pattern for Tabs labels
* "tab-data-" pattern for Tabs content containers
*
* Attributes like "panes, cols, skipData" mostly relate to rendering of data on
* Drupal side. "skipData" indicates to not try to render REST view in Drupal, if
* set to TRUE.
*
* See example in stories.
*
* */
import React from 'react';
import {ThemeProvider, makeStyles} from '@material-ui/core/styles';
import {themeExtender} from '../../../.storybook/theme';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CustomMuiButton from '../../01_atoms/CustomMuiButton';
import {parseGutenbergReact} from "../../../api/global";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && (
            <Box p={3}>
              <Typography component="div">{children}</Typography>
            </Box>
        )}
      </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  IncTabs: {
    flexGrow: 1,
    padding: '10px 0 40px 0',
  },
  IncTabsTxt: {
    padding: '24px 0px',
    backgroundColor: theme.palette.grey[500],
    "& > div >  div >.MuiBox-root": {
      padding: 0,
    }
  },
  IncTabsLbl: {
    fontSize: 22,
    fontWeight: 700,
    margin: 0,
    lineHeight: .5,
  },
  tabWrapper: {
    paddingLeft: 0,
  },
}));

export default function CustomMuiTabs(props) {
  let {block, lbl, ctaLinks, children, panes, cols, skipData} = props;
  if ((!cols || !children) && block?.data) {
    panes = !panes ? block?.data.panes : panes;
    cols = !cols ? block?.data.cols : cols;
  }
  if (!cols && !children) {
    return null;
  }
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let i = 0, t = 0, ctaBtn;

  return (
      <ThemeProvider theme={themeExtender}>
        <div className={classes.IncTabs}>
          <Container className={classes.tabWrapper}>
            <Box color="success.main">
              <h2 className={classes.IncTabsLbl}>{lbl}</h2>
            </Box>
            <Grid container>
              <Grid item xs={12}>
                {/* TABS LABELS: */}
                <Tabs value={value} onChange={handleChange}
                      aria-label="simple tabs example">
                  {cols && cols.map((val, ind) => {
                    return (
                        <Tab key={`tab-lbl-${panes.uniqueID}-${ind}`}
                             disableRipple
                             label={panes.titles[ind].text} {...a11yProps(i)} />)
                  })
                  }
                  {children && children.map((val, ind) => {
                    if (val.key.includes('tab-lbl-')) {
                      const out = (
                          <Tab key={val.key} disableRipple
                               label={val.props.children} {...a11yProps(i)} />
                      );
                      i += 1;
                      return out;
                    }
                  })
                  }
                </Tabs>
              </Grid>
            </Grid>
          </Container>
          {/* TABS CONTENT: */}
          <Box className={classes.IncTabsTxt} bgcolor='white' py="10">
            <Container>
              {cols && cols.map((val, ind) => {
                const out = (
                    <TabPanel key={`tab-data-${panes.uniqueID}-${ind}`}
                              component='div' value={value} index={ind}>
                      {val.data?.length > 0 && val.data.map((gb, igb) => {
                        return parseGutenbergReact(`inner-${igb}`, gb, skipData);
                      })
                      }
                    </TabPanel>
                );
                return out;
              })
              }
              {children && children.map((val, ind) => {
                if (val.key.includes('tab-data-')) {
                  ctaBtn = false;
                  if (ctaLinks) {
                    ctaLinks.map((cta) => {
                      if (`tab-data-${cta.id}` == val.key) {
                        ctaBtn = cta;
                      }
                    });
                  }
                  const out = (
                      <TabPanel key={val.key} component='div'
                                value={value} index={t}>
                        {val.props.children}

                        {(ctaBtn && ctaBtn.label) &&
                        <Box textAlign='center' mt={4}>
                          <CustomMuiButton
                              variant={'contained'}
                              label={ctaBtn.label}
                              color={'primary'}
                              size={'large'}
                              href={ctaBtn.link ? ctaBtn.link : null}
                          />
                        </Box>
                        }
                      </TabPanel>
                  );
                  t += 1;
                  return out;
                }
              })
              }
            </Container>
          </Box>
        </div>
      </ThemeProvider>
  );
}
